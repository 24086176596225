<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.25006 10L9.46657 6.14019C9.72166 5.83408 9.84921 5.68102 9.84921 5.5C9.84921 5.31898 9.72166 5.16592 9.46657 4.85982L6.25006 1"
      :stroke="iconStyle"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.00012 10L4.21663 6.14019C4.47172 5.83408 4.59927 5.68102 4.59927 5.5C4.59927 5.31898 4.47172 5.16592 4.21663 4.85982L1.00012 1"
      :stroke="iconStyle"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '11',
    },
    width: {
      type: String,
      default: '11',
    },
    color: {
      type: String,
      default: 'primary-purple-700',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return `var(--${this.color})`
    },
  },
}
</script>
